import CTUP from '@/assets/svg/CTUP_logo.svg';
import CTEletro from '@/assets/svg/CTEletroGreen.svg';
import CTAuto from '@/assets/svg/AutoWhite.svg';
import WrapperContent from '@components/Layout/WrapperContent';
import NewsTimeLine, { PropsTitle } from '@/components/Layout/NewsTimeLine';
import { TimeLine } from '@/types/types';
import SponsorBanner from '@/components/Layout/EditorialCategorySponsorBanner';
import Chip from '@/components/Layout/Chip';
import { ctauto, eletro, kabum, mglBlue, mglGray } from '@/styles/designSystemColors';
import React from 'react';

interface FeaturedEditorialCategoryData {
  timeline: TimeLine[];
  variant: 'ctup' | 'eletro' | 'ctauto';
}

type EditorialCategoryObjProps = {
  background: string;
  logo: React.ReactNode;
  routeType: 'id' | 'categoria';
  route?: string;
};

type EditorialCategoryProps = {
  ctup: EditorialCategoryObjProps;
  eletro: EditorialCategoryObjProps;
  ctauto: EditorialCategoryObjProps;
  ctnegocios: EditorialCategoryObjProps;
};

export const editorialCategoryProps: EditorialCategoryProps = {
  ctup: {
    background: kabum.kabumBlue,
    logo: <CTUP />,
    routeType: 'id',
    route: '155778?merge=155790,155842',
  },
  eletro: {
    background: eletro.eletroBlack,
    logo: <CTEletro />,
    routeType: 'categoria',
    route: 'eletro',
  },
  ctauto: {
    background: ctauto.ctautoRed,
    logo: <CTAuto />,
    routeType: 'categoria',
    route: 'ctauto',
  },
  ctnegocios: {
    background: mglBlue.mglBlue500,
    logo: <React.Fragment />,
    routeType: 'categoria',
  },
} as const;

export default function FeaturedEditorialCategory({ timeline, variant }: FeaturedEditorialCategoryData) {
  const PropTitle: PropsTitle = {
    color: mglGray.mglGray50,
  };

  return (
    <div>
      <WrapperContent backgroundColor={editorialCategoryProps[variant].background} paddingOnBottom>
        {variant === 'ctup' && (
          <div className="absolute right-0 top-0 hidden h-[190px] rounded-bl-[150px] bg-kabumOrange tablet:block tablet:w-[calc(35%-40px)] largeDesktop:w-[calc(30%-40px)] [@media_screen_and_(min-width:1600px)]:w-[calc(25%-40px)]" />
        )}
        <SponsorBanner isFeatured variant={variant} />
        <div className="ml-0 grid grid-cols-1 grid-rows-5 gap-5 [grid-auto-columns:1fr] tablet:grid-cols-2 tablet:grid-rows-3 desktop:grid-cols-3 desktop:grid-rows-2">
          {timeline.map(n => (
            <NewsTimeLine news={n} key={n.url} propsTitle={PropTitle} headingTag="h3" />
          ))}
        </div>
        <div className="flex [&>a]:mx-auto [&>a]:my-0 ">
          <Chip
            texto="ver mais conteúdo"
            chipSize="medium"
            title="ver mais conteúdo"
            borderColor="white"
            posIcon="chevron-right"
            link={`/${variant}/`}
            color="white"
            hover="black"
          />
        </div>
      </WrapperContent>
    </div>
  );
}
